import axios, { AxiosResponse } from 'axios'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { application } from 'src/redux/store'
import { addTodos, getTodos, updateTodos } from './slice'
import { dashboardTodoTypes } from './type'

function* getTodosHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/todo?date=${payload.date}`)
    )
    yield put(getTodos(res.data.data.Records))
  } catch (error) {}
}

function* addTodosHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/todo`, {
        content: payload.content,
        date: payload.date,
        subject: payload.subject,
      })
    )
    yield put(addTodos(res.data.data.Records))
  } catch (error) {}
}

function* updateTodosHandler({ payload, date }: any) {
  try {
    const payloadId = payload.map((data: any) => data.id)
    const res: AxiosResponse = yield call(() =>
      axios.put(`${application.api}/admin/todo`, {
        id: payloadId,
        done: true,
        date: date,
      })
    )
    yield put(updateTodos(res.data.data.Records))
  } catch (error) {}
}

export function* dashboardTodoSaga() {
  yield all([
    takeEvery(dashboardTodoTypes.GET_TODOS, getTodosHandler),
    takeEvery(dashboardTodoTypes.ADD_TODOS, addTodosHandler),
    takeEvery(dashboardTodoTypes.UDPATE_TODOS, updateTodosHandler),
  ])
}
