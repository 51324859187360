import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  todos: [],
  allTodos: [],
}

const TodoListsSlice = createSlice({
  name: 'Todolist',
  initialState,
  reducers: {
    showTodoList: (state, action) => {
      state.todos = action.payload
    },
    showAllTodoList: (state, action) => {
      state.allTodos = action.payload
    },
    updateTodoList: (state: any, action: any) => {
      const FindIndex = state.todos.findIndex(action.payload)
      state.todos[FindIndex] = {
        content: action.payload.content,
        date: action.payload.date,
        done: action.payload.done,
        id: action.payload.id,
        subject: action.payload.subject,
      }
    },
    addTodo: (state: any, action: any) => {
      state.todos.push(action.payload)
      state.allTodos.push(action.payload)
    },
    deleteTodo: (state: any, action: any) => {
      state.todos = state.todos.filter((el: any) => el.id !== action.payload)
      state.allTodos = state.allTodos.filter(
        (el: any) => el.id !== action.payload
      )
    },
    updateSingleTodo: (state: any, action: any) => {
      const FindIndex: any = state.todos.findIndex(action.payload)
      state.todos[FindIndex] = {
        content: action.payload.content,
        date: action.payload.date,
        done: action.payload.done,
        id: action.payload.id,
        subject: action.payload.subject,
      }
    },
  },
})

export const {
  showTodoList,
  deleteTodo,
  updateTodoList,
  addTodo,
  showAllTodoList,
  updateSingleTodo,
} = TodoListsSlice.actions

export default TodoListsSlice.reducer
